<template>
  <div id="form-crud-category-scenario">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">
        <div class="col-12 col-md-6">

          <div class="form-group">
            <label for="select-cat">{{ $tc('common.category', 1) }} *</label>

            <select id="select-cat" class="form-control" v-model="selectCategory">
              <option v-for="(category, index) in CategoryModule.categories" :key="index" :value="category.id">
                {{ category.name }}
              </option>
            </select>
            <div v-if="errorsForm.category">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.category.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="select-scenario">{{ $t('common.singleScenario') }} *</label>

            <select id="select-scenario" class="form-control" v-model="selectScenario">
              <option v-for="(scenario, index) in ScenarioModule.scenarios" :key="index" :value="scenario.id">
                {{ scenario.name }}
              </option>
            </select>
            <div v-if="errorsForm.scenario">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.scenario.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="form-delay">{{ $t('pages.categoryScenario.timeFrame') }}</label>
            <input class="form-control" id="form-delay" v-model="categoryScenario.delay" type="number" step="0.01">
            <div v-if="errorsForm.delay">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.delay.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <input type="submit" class="btn mt-4 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    categoryScenario: Object,
    id: Number,
    selectedCategory: Number,
    selectedScenario: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      selectCategory: this.selectedCategory,
      selectScenario: this.selectedScenario,
      errorsForm: [],
    }
  },
  watch: {
    selectedCat: function() {
      this.selectCategory = this.selectedCategory
    },
    selectedScenario: function() {
      this.selectScenario = this.selectedScenario
    }
  },
  created: function() {
    this.$store.dispatch('fetchCategories', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchScenarios', {
      perPage: null,
      page: null
    })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // Handle var
      this.categoryScenario.category = this.selectCategory
      this.categoryScenario.scenario = this.selectScenario

      // If category (create or update)
      if(this.actionForm === 'edit') {
        this.edit()
      } else {
        this.create()
      }
    },
    /**
     * Create categoryScenario (submit form)
     */
    create() {

      this.showLoader()

      this.$store.dispatch('createCategoryScenario', this.categoryScenario)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update categoryScenario (submit form)
     */
    edit() {
      this.showLoader()

      this.$store.dispatch('updateCategoryScenario', { id: this.categoryScenario.id, categoryScenario: this.categoryScenario })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to cat-sc-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'cat-sc-index' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.categoryScenario.editCategoryScenario')
      }
      else {
        value = this.$t('pages.categoryScenario.createCategoryScenario')
      }

      return value
    },
    ...mapState(['CategoryScenarioModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ScenarioModule']),
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
